// Copyright 2015-2023 Nstream, inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {OutputSettings} from "../output/OutputSettings";
import type {Output} from "../output/Output";
import {ByteOutput} from "./ByteOutput";

/** @internal */
export class ByteOutputUint8Array extends ByteOutput<Uint8Array> {
  constructor(array: Uint8Array | null, size: number, settings: OutputSettings) {
    super(array, size, settings);
  }

  override bind(): Uint8Array {
    return this.toUint8Array();
  }

  override clone(): Output<Uint8Array> {
    return new ByteOutputUint8Array(this.cloneArray(), this.size, this.settings);
  }
}
